import { render, staticRenderFns } from "./DoublecateDialog.vue?vue&type=template&id=1bb97a5b"
import script from "./DoublecateDialog.vue?vue&type=script&lang=js"
export * from "./DoublecateDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports