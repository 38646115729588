import router from '@/router'
import { GetByRestaurantId } from '@core/api/Branch'
import { GetInfo } from '@core/api/Restaurant'
import { ref, watch } from '@vue/composition-api'

export default function useBranchesList() {
  const tableColumns = [
    { text: 'id', value: 'Id' },
    { text: 'name', value: 'Name', width: 200 },
    { text: 'Open', value: 'OpenTime' },
    { text: 'Close', value: 'CloseTime' },
    { text: 'STATUS', value: 'Status', sortable: false },
    { text: 'Category', value: 'Category', sortable: false },
    { text: 'Addons', value: 'Addons', sortable: false },
    { text: 'Options', value: 'Options', sortable: false },
    { text: 'Items', value: 'Items', sortable: false },
    { text: 'actions', value: 'Actions', sortable: false },
  ]

  const branchListTable = ref([])
  const ResInfo = ref({})
  const editedItem = ref({})
  const searchQuery = ref('')
  const resid = ref(router.currentRoute.params.id)
  const branchid = ref(0)
  const statusFilter = ref(null)
  const totalbranchListTable = ref(0)
  const loading = ref(false)
  const resname = ref(router.currentRoute.params.name)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const branchTotalLocal = ref([])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchBranches = () => {
    GetByRestaurantId({ id: resid.value, any: searchQuery.value, status: statusFilter.value })
      .then(response => {
        // handle success
        branchListTable.value = response
        totalbranchListTable.value = response.lenght
        branchTotalLocal.value = response.lenght
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchRestaurants = () => {
    GetInfo({ ResId: resid.value })
      .then(response => {
        ResInfo.value = response
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, options], () => {
    loading.value = true
    fetchBranches()
  })

  return {
    branchListTable,
    tableColumns,
    searchQuery,
    resid,
    statusFilter,
    totalbranchListTable,
    loading,
    options,
    branchTotalLocal,
    fetchBranches,
    branchid,
    editedItem,
    resname,
    ResInfo,
    fetchRestaurants,
  }
}
