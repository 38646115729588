<template>
  <v-dialog :value="isEditRes" max-width="750px" @input="val => $emit('update:is-edit-res', val)">
    <v-card>
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-card-title>
          <span class="headline">Edit Restaurant</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="ResData.Name"
            outlined
            dense
            :rules="[validators.required, validators.usernameValidator(ResData.Name, 6)]"
            label="Name"
            placeholder="Full Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            v-model="ResData.Email"
            :rules="[validators.emailValidator]"
            outlined
            dense
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="ResData.PhoneNumber"
            :rules="[validators.required, validators.lengthValidator(ResData.PhoneNumber, 10)]"
            outlined
            dense
            type="number"
            label="Phone Number"
            placeholder="Phone Number"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            v-model="ResData.Type"
            outlined
            dense
            label="Type"
            placeholder="Type"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-select
            v-model="ResData.Status"
            :rules="[validators.required]"
            label="Status"
            :items="$store.state.status['restaurants']"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>
          <v-text-field
            v-model="ResData.Description"
            outlined
            dense
            label="Description"
            placeholder="Description"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { Edit } from '@core/api/Restaurant'
import { emailValidator, lengthValidator, required, usernameValidator } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isEditRes',
    event: 'update:is-edit-res',
  },
  props: {
    isEditRes: {
      type: Boolean,
      required: true,
    },
    Restaurant: { type: Object, required: true },
  },
  watch: {
    Restaurant(New) {
      this.ResData = New
    },
  },
  setup(props, { emit }) {
    const blankResData = {
      Id: undefined,
      Name: '',
      Description: '',
      PhoneNumber: '',
      Status: 'active',
      Type: '',
      Email: '',
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const ResData = ref(JSON.parse(JSON.stringify(blankResData)))
    const resetResData = () => {
      ResData.value = JSON.parse(JSON.stringify(blankResData))
      resetForm()
      emit('update:is-edit-res', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        Edit(ResData.value).then(() => {
          emit('refetch-data')
          emit('update:is-edit-res', false)
        })

        resetResData()
      } else {
        validate()
      }
    }

    const close = () => {
      resetResData()
    }
    return {
      close,
      resetResData,
      form,
      onSubmit,
      ResData,
      valid,
      validate,
      mdiPlus,

      // validation
      validators: { required, lengthValidator, usernameValidator, emailValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
